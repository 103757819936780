import React from "react";
import { Helmet } from "react-helmet";
import useSiteMetadata from "../../../hooks/useSiteMetadata";

const Seo = ({ lang, title, description }) => {
	const siteMetadata = useSiteMetadata();
	const metaDescription = description || siteMetadata.description;
	const metaLang = lang || siteMetadata.lang;

	return (
		<Helmet
			htmlAttributes={{ metaLang }}
			title={title}
			titleTemplate={`${siteMetadata.title} - %s`}
		>
			<meta name="description" content={metaDescription} />
		</Helmet>
	);
};

export default Seo;
